import { website } from '@/axios'
export const storeCategoriesLink = 'admin/stores-categories'

export default {
  getDetails (id) {
    return website().get(`${storeCategoriesLink}/${id}`)
  },
  add (payload) {
    return website().post(storeCategoriesLink, payload)
  },
  update (id, payload) {
    return website().patch(`${storeCategoriesLink}/${id}`, payload)
  },
  getAll () {
    return website().get(`${storeCategoriesLink}`)
  }
}
