<template>
  <div>
    <dashboard-page-title @addEvent="$router.push({name: 'storeCategories.add'})">{{ $t('storeCategories.storeCategories') }}</dashboard-page-title>

    <main-table :fields="fields" :baseURL="SFZ_API_LINK" :list_url="storeCategoriesLink" :limitStatus="true"></main-table>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import { SFZ_API_LINK } from '@/config/constant'
import { storeCategoriesLink } from '../services/storeCategories'

export default {
  name: 'storeCategories',
  mounted () {
    core.index()
  },
  data () {
    return {
      SFZ_API_LINK,
      storeCategoriesLink,
      fields: [
        { label: this.$t('main.name'), key: 'name' },
        {
          label: this.$t('main.actions'),
          key: 'actions',
          type: 'actions',
          actions: [
            {
              text: this.$t('main.view'),
              icon: 'ri-eye-fill',
              color: 'secondary',
              ifNavigate: true,
              routeName: 'storeCategories.view',
              routeParams: ['id']
            },
            {
              text: this.$t('main.update'),
              icon: 'ri-ball-pen-fill',
              color: 'info',
              ifNavigate: true,
              routeName: 'storeCategories.update',
              routeParams: ['id']
            },
            {
              text: this.$t('main.delete.delete'),
              icon: 'ri-delete-bin-line',
              color: 'danger',
              url: storeCategoriesLink,
              baseUrl: SFZ_API_LINK,
              titleHeader: this.$t('storeCategories.storeCategory'),
              textContent: 'name',
              showAlert: true
            }
          ]
        }
      ]
    }
  }
}
</script>
<style>

</style>
